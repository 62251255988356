import React from "react"
import Layout from "../../components/modules/Layout"
import BlockWrapper from "../../components/technical/BlockWrapper"

const ErrorPage = () => {
  return (
    <Layout language="dk">
      <BlockWrapper block={{}}>
        <div className="py-32 text-center">
          <h1 className="text-8xl">404</h1>
          <p>Siden blev ikke fundet</p>
        </div>
      </BlockWrapper>
    </Layout>
  )
}

export default ErrorPage
